import React from "react"
import { Head as DefaultHead } from "../head"
import { buttonDefaultStyle } from "../components/Buttons"
import { Footer } from "../components/Footer"
import { Main } from "../components/Main"
import { Menubar } from "../components/Menu"
import { Section } from "../components/Section"
import { CONTACT_EMAIL } from "../utils"
import { ContentContainer } from "../components/Container"
import { graphql } from "gatsby"

const factSheet = [
  {
    title: "Release",
    value: "2024",
  },
  {
    title: "Players",
    value: "Singleplayer",
  },
  {
    title: "Platforms",
    value: "Windows, Mac",
  },
  {
    title: "Languages",
    value: "English & German (on launch)",
  },
  {
    title: "Twitter",
    value: (
      <a
        href="https://twitter.com/frameland"
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        @frameland
      </a>
    ),
  },
  {
    title: "Contact",
    value: (
      <a href={`mailto:${CONTACT_EMAIL}`} target="_blank" rel="noreferrer" className="underline">
        {" "}
        {CONTACT_EMAIL}
      </a>
    ),
  },
  {
    title: "Developer",
    value: "Frameland Games",
  },
]

function Presskit() {
  return (
    <Main>
      <Menubar />
      <Section bg="bg-fl-turkis-dark" color="text-fl-text">
        <ContentContainer>
          <h1 className="text-4xl font-serif text-center text-fl-turkis-light mb-12">Presskit</h1>
          <Heading>
            Frameland
            <small className="block text-sm opacity-80">A short RPG with a big heart.</small>
          </Heading>
          <P className="max-w-md mx-auto font-bold text-fl-white">
            Frameland is a game about humans, robots, and what's in between. Venture through a world
            past the brink of destruction and discover carefully concealed truths. Fight vicious
            swarms in turn-based&nbsp;battles with a twist: time your attacks for
            maximum&nbsp;impact!
          </P>
          <P className="max-w-md mx-auto">
            An unknown world. A looming threat of destruction. Two perspectives. A journey back
            home.
          </P>
          <P className="max-w-md mx-auto">
            Frameland features unique old-school pixel art and 16-bit music for your nostalgia fix.
            Timed turn-based battles keep you on the edge of your seat. Explore a rich world and
            discover Condorra’s darkest secrets.
          </P>
          <div className="max-w-md mx-auto mb-12 mt-6 sm:columns-2 gap-3">
            <a
              href="/assets/presskit/frameland_description.pdf"
              className={buttonDefaultStyle + " block mb-2"}
              download
            >
              ↓ Full Description
            </a>
            <a
              href="/assets/presskit/frameland_presskit.zip"
              className={buttonDefaultStyle + " block"}
              download
            >
              ↓ Download Assets
            </a>
          </div>

          <Heading>Factsheet</Heading>
          <ul className="bg-fl-sky/10 border-2 border-fl-sky/25 max-w-md mx-auto">
            {factSheet.map((row, index) => (
              <li className="flex flew-col px-4 py-2 border-b-2 border-fl-sky/25" key={row.title}>
                <span className="block w-1/3 opacity-60">{row.title}</span>
                <span className="block">{row.value}</span>
              </li>
            ))}
          </ul>

          <Heading>Keyart</Heading>
          <Image src="keyart_full.png" alt="Keyart" />
          <Image src="keyart_full_logo.png" alt="Keyart with Logo" />

          <Heading>Screenshots</Heading>
          <div className="sm:columns-2">
            <Image src="screens/1.png" alt="Story cutscene with Ryiu" />
            <Image src="screens/2.png" alt="Blackhole Attack" />
            <Image src="screens/3.png" alt="Exploring an island" />
            <Image src="screens/4.png" alt="Emergency Protocol activated" />
            <Image src="screens/5.png" alt="Battle" />
            <Image src="screens/6.png" alt="Harbor and market in Trovo" />
          </div>

          <Heading>Logo</Heading>
          <div className="flex justify-center">
            <Image src="logo.png" alt="Logo" hasShadow={false} />
          </div>
          <div className="flex justify-center">
            <Image src="logo_subtitle.png" alt="Logo" hasShadow={false} />
          </div>

          <Heading>Team & History</Heading>
          <div className="bg-fl-turkis-dark border-fl-turkis-dark border max-w-md mx-auto mt-5">
            <ul className="flex flex-row justify-center mt-6 md:flex-row">
              <Char
                char="markus"
                role={
                  <span>
                    Programming
                    <br />
                    Game Design
                  </span>
                }
              />
              <Char
                char="sebastian"
                role={
                  <span>
                    Art
                    <br />
                    Map Design
                  </span>
                }
              />
              <Char
                char="mario"
                role={
                  <span>
                    Narrative
                    <br />
                    Music
                  </span>
                }
              />
            </ul>
            <P className="font-serif text-sm p-4 mb-0">
              Frameland Games is a game company based in Vienna, Austria. We believe that good games
              don't have to be&nbsp;big. In 2016, as a small team of three, we started working on a
              new project: Our&nbsp;own&nbsp;take on the&nbsp;classical&nbsp;RPG!
            </P>
            <hr className="opacity-50" />
            <P className="font-serif text-sm px-4 pt-2 pb-0 mb-0 leading-6">
              <b className="block">And additional help from:</b>

              <span className="block">
                <a
                  href="https://ko-fi.com/sabbi_tabbi"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block underline underline-offset-2"
                >
                  Laura Löffler
                </a>{" "}
                (facesets, keyart & more)
              </span>
              <span className="block">
                <a
                  href="http://finalbossblues.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block underline underline-offset-2"
                >
                  Jason Perry
                </a>{" "}
                (enemy design, sprites & tilesets)
              </span>
            </P>
          </div>
          <div className="max-w-md mx-auto">
            <P className="mt-4">
              After about a year, the first demo was ready and presented at the Reversed Game
              Festival in Vienna, Austria.
              <span className="block mt-4">
                <Image
                  src="reversed.jpg"
                  alt="Reversed Game Festival, 2017"
                  caption="Demoing the first time at Reversed Game Festival in Vienna, 2017"
                />
              </span>
            </P>
            <P>
              Based on the feedback we gathered, we reworked the combat system to make it more
              engaging. We have kept the classic turn-based nature that allows you to plan ahead,
              but we have added a unique timing mechanic that adds a bit of skill to each battle.
            </P>
            <P>
              Now, after 7 years in development, we are nearing the finish line and will release
              Frameland in 2024.
            </P>
          </div>
        </ContentContainer>
      </Section>
      <Footer />
    </Main>
  )
}

function Image({ src, alt, caption, hasShadow = true }) {
  const path = "/assets/presskit/" + src
  const classes = `cursor-pointer mb-3 block border-fl-turkis-dark/50 ${
    hasShadow ? "border-2" : ""
  }`
  return (
    <>
      <a href={path} target="_blank" rel="noreferrer" className={classes}>
        <img src={path} alt={alt} />
      </a>
      {caption && <span className="text-xs opacity-50 -mt-3 block">{caption}</span>}
    </>
  )
}

function Heading({ children }) {
  return (
    <h2 className="text-xl mt-12 mb-3 text-center font-serif font-bold text-gray-100">
      {children}
    </h2>
  )
}

function P({ children, className = "" }) {
  return <p className={"text-md mb-3 " + className}>{children}</p>
}

function Char({ char, role }) {
  return (
    <li key={char} className="flex items-center mr-2 small:mr-6">
      <img
        src={`/assets/chars/${char}.png`}
        alt={`Developer ${char}`}
        className="hidden small:inline-block"
      />
      <div className="ml-2">
        <p className="uppercase text-xs tracking-wider">{char}</p>
        <p className="text-xs opacity-50">{role}</p>
      </div>
    </li>
  )
}

export function Head() {
  return (
    <DefaultHead
      title="Frameland: Presskit"
      description="Frameland: Presskit for the short RPG with a big heart."
    />
  )
}

export default Presskit

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
